/* molengo-400normal - latin */
@font-face {
  font-family: 'Molengo';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Molengo Regular '),
    local('Molengo-Regular'),
    url(/app/static/media/molengo-latin-400.bc11ee53.woff2) format('woff2'), 
    url(/app/static/media/molengo-latin-400.028d2c7c.woff) format('woff'); /* Modern Browsers */
}


